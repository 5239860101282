<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="负责人：">
					<el-autocomplete
						v-model.trim="formInline.principalName"
						:fetch-suggestions="querySearchAsync"
						clearable
						placeholder="请输入内容"
					></el-autocomplete>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:operation-column-width="220"
				:is-show-selection="false"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
				</template>
				<!-- 插槽操作栏固定属性operation -->
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button
						@click="modifyParkInfo($event, false, scope.row)"
						size="mini"
						:type="scope.row.enabled ? 'info' : 'warning'"
						:disabled="scope.row.enabled"
					>
						修改
					</el-button>
					<el-button @click="resetPwd(scope.row)" size="mini" :type="scope.row.enabled ? 'info' : 'danger'" :disabled="scope.row.enabled">
						重置密码
					</el-button>
				</template>
			</Table>
		</div>
		<CreateAccount ref="createAccount" @getList="getList" :isShowDetail="isShowDetail" />
		<NewForm ref="newForm" @getList="getList" />
	</div>
</template>

<script>
export default {
	name: 'accountManager',
	components: {
		Table: () => import('@/components/Table/table'),
		CreateAccount: () => import('./newDialog.vue'),
		NewForm: () => import('./new.vue'),
	},
	data() {
		return {
			formInline: {
				principalName: '',
			},
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '账户名称',
					prop: 'displayName',
					formatter: (row) => {
						return row.displayName || '-';
					},
				},
				{
					label: '账号',
					prop: 'loginName',
					formatter: (row) => {
						return row.loginName || '-';
					},
				},
				{
					label: '账号级别',
					prop: 'regionCode',
					formatter: (row) => {
						return row.regionCode == '0'
							? '全国'
							: row.regionCode.length == 2
							? '省级'
							: row.regionCode.length == 4
							? '市级'
							: row.regionCode.length == 6
							? '区县级'
							: '乡镇级';
					},
				},
				{
					label: '开通时间',
					prop: 'createTime',
					formatter: (row) => {
						return row.createTime || '-';
					},
				},
				{
					label: '负责人',
					prop: 'principalName',
					formatter: (row) => {
						return row.principalName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '是否启用',
					prop: 'enabled',
				},
			],
			isShowDetail: false,
			options: [],
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		// 查询条件
		onSearch() {
			this.queryData.current = 1;
			this.getList('');
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? '' : (this.loading = true);
			let data = {
				...this.formInline,
				...this.queryData,
			};
			this.$http
				.get(this.api.getRegionManagementGroups + '.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.principalName,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			if (!row) {
				this.$refs.newForm.init();
				return;
			}
			this.isShowDetail = isShowDetail;
			this.$refs.createAccount.init(row);
		},
		// 切换上岗下岗
		changeOpen(row) {
			this.$http
				.put(this.api.getRegionManagementGroups + `/${row.id}.json`, row)
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		// 密码重置
		resetPwd(row) {
			this.$confirm('确定要重置密码吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.$http
						.put(this.api.modifyPasswords + `.json`, { type: 'regionalAccount', id: row.id })
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success('重置成功');
								this.getList();
							}
						})
						.catch((e) => {});
				})
				.catch(() => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}

// .el-button--warning {
// 	background-color: #38b4f2;
// 	border-color: #38b4f2;
// 	color: #fff;
// }
</style>
